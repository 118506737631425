<template>
    <div>
        <v-form class="auth-form auth-form-doc" v-model="valid">
            <div class="auth-card-form">
                <v-card 
                    width="100%"
                    height="100%"
                    elevation="0"
                    rounded="0">
                    <v-card-text>
                        <v-row class="auth-main-row-wrap">
                            <v-col cols="auto" sm="auto" md="auto" class="amrw-logo-part d-none d-md-block">
                                <div class="d-flex flex-column" style="height:100%;">
                                    <v-row class="auth-logo-row mb-auto" no-gutters>
                                        <v-col cols="12" sm="12" md="12">
                                            <div class="d-flex align-center">
                                                <img class="al-logo" src="@/assets/logo_short.svg">
                                                <div class="auth-logo-title">
                                                    <div class="alt-main">Интегро</div>
                                                    <div class="alt-sub">Администрирование</div>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <div>
                                        <div class="auth-copyright text-center">© Компания Интегро 2012-{{ todayYear }}</div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col>
                                <div class="d-flex flex-column" style="height:100%;">
                                    <v-row class="flex-grow-0" no-gutters>
                                        <v-col cols="12" sm="12" md="12" class="text-right">
                                            <v-btn-toggle 
                                                :value="getLanguageId()"
                                                dense
                                                mandatory
                                                color="#fff"
                                                active-class="integro-red-color"
                                                borderless
                                                light
                                                background-color="transparent"
                                                class="auth-lang-selector"
                                            >

                                                <v-btn
                                                    :value="0"
                                                    @click="setLanguage('kk')"
                                                    small
                                                >
                                                    KZ
                                                </v-btn>

                                                <v-btn
                                                    :value="1"
                                                    @click="setLanguage('ru')"
                                                    small
                                                >
                                                    RU
                                                </v-btn>

                                            </v-btn-toggle>  
                                        </v-col>
                                    </v-row>
                                    <div class="auth-form-field-wrap d-flex align-center flex-grow-1">
                                        <div style="width:100%;">
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="12">
                                                    <h2 class="login-form-title">{{ $t('Вход_в_систему') }}</h2>
                                                </v-col>
                                            </v-row>

                                            
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field
                                                        :readonly="isPendingData"
                                                        :disabled="isPendingData"
                                                        :rules="[rules.required]"
                                                        v-model="login"
                                                        prepend-inner-icon="mdi-account"
                                                        :placeholder="$t('Логин')"
                                                        required 
                                                        outlined 
                                                        background-color="#fff"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field 
                                                        :readonly="isPendingData"
                                                        :disabled="isPendingData"
                                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :rules="[rules.required, rules.min]"
                                                        :type="showPassword ? 'text' : 'password'"
                                                        v-model="password"
                                                        name="input-10-1"
                                                        @click:append="showPassword = !showPassword"
                                                        @keydown.enter="fetchAuthorize({ login, password })"
                                                        prepend-inner-icon="mdi-lock" 
                                                        :placeholder="$t('Пароль')"
                                                        autocomplete="new-password"
                                                        required 
                                                        outlined 
                                                        background-color="#fff"
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-0" no-gutters>
                                                <v-col cols="12">
                                                    <v-btn 
                                                        class="ma-0 send-auth-btn integro-red-color"
                                                        :loading="isPendingData"
                                                        :disabled="!valid || isPendingData"
                                                        depressed
                                                        @click="fetchAuthorize({ login, password })"
                                                        block
                                                        x-large
                                                    >
                                                        {{ $t('Войти') }}

                                                        <template v-slot:loader>
                                                            <semipolar-spinner
                                                                v-if="isPendingData"
                                                                :animation-duration="2000"
                                                                :size="50"
                                                                color="white"
                                                                class="auth-loader"
                                                            />
                                                        </template>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <!-- <v-row v-if="getErrorMessage">
                                                <v-col>
                                                    <v-alert dense outlined type="error">
                                                        {{ getErrorMessage }}
                                                    </v-alert>
                                                    v-if="isPendingData"
                                                </v-col>
                                            </v-row> -->
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="12">
                                                    <a
                                                        class="text-decoration-none restore-pass"
                                                        > {{ $t('Забыли_пароль?') }}</a>
                                                </v-col>
                                            </v-row>
                                                
                                        </div>
                                    </div>
                                    <v-row class="flex-grow-0 bottom-panel-info" no-gutters>
                                        <v-col cols="6" sm="6" md="6">
                                            <!-- <div class="bpi-pattern">
                                                {{ $t('Техническая_поддержка:') }}
                                                <div class="d-flex">
                                                    <a
                                                        href="#"
                                                        class="text-decoration-none">
                                                        <i class="fas fa-phone-alt"></i>
                                                        телефон
                                                    </a>
                                                    <span class="mr-3">,</span>
                                                    <a
                                                        href="#"
                                                        class="text-decoration-none">
                                                        <i class="fas fa-envelope"></i>
                                                        email
                                                    </a>
                                                </div>
                                            </div> -->
                                        </v-col>
                                        <v-col cols="6" sm="6" md="6" class="d-flex justify-end">
                                            <div class="bpi-pattern">
                                                <div class="d-flex">
                                                    {{ $t('Версия') }}: {{ getVersion() }}
                                                </div>
                                                <!-- <a href="#">{{ $t('Что_нового?') }}</a> -->
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
        </v-form>
        <img src="@/assets/login6.jpg" id="bgFolder">
    </div>    
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SemipolarSpinner } from '../../node_modules/epic-spinners'
import i18n from '@/i18n'

export default {
    components:{
        SemipolarSpinner,
    },
    data: () => ({
        readOnly: false,
        disabled: false,
        loader: null,
        dialog: true,
        showPassword: false,
        login: '',
        password: '',
        currentLanguage: 1,
        valid: false,
        rules: {
            required: function (value) {
                return (value !== undefined && !!value) || 'Не может быть пустым.';
            },
            min: function (value) {
                return (value !== undefined && value.length >= 3) || 'Не менее 3 символов';
            },
        },
        todayYear: (new Date()).getFullYear(),
        tab: null,
    }),
    methods: {
        ...mapActions('auth', ['fetchAuthorize']),        
        setLanguage(lang) {
            localStorage.setItem('AppLocale', lang)
            i18n.locale = lang;
            this.$vuetify.lang.current = lang;
        },
        getLanguageId() {
            switch (i18n.locale)
            {
                case "ru":
                    return 1;

                case "kk":
                    return 0;

                default:
                    return 1;
            }
        },
        getVersion() {
            let version = process?.env?.VUE_APP_VERSION ?? "0.0.0.0.0000"

            if (this.isConfiguratioC5)
                return `${version}/${this.userRegion}`;
            else
                return `${version}`;
        }
    },
    computed: {
        ...mapGetters('auth', ['getErrorMessage', 'isPendingData']),  
        ...mapGetters({ userRegion: 'getUserRegion' }),      
        logoComponentName(){            
            if (this.isConfiguratioC5)
                return 'LoginLogoC5';

            return 'LoginLogoC4';
        },
        isConfiguratioC5() {
            return process.env.VUE_APP_CONFIGURATION == 'C5';
        }
    },
    watch: {
        loader() {
            this.disabled = true;
        },
        getErrorMessage(newValue){
            if (newValue)
                this.$notify.alert(newValue, {durations: {alert: 0}});
        }
    }
};
</script>

<style lang="scss" scoped>
.auth-form{
    /*min-width: 1000px;*/
}
.auth-form *:not(.alt-main, .alt-sub, i, span){
    font-family: SegoeUI!important;
}
.login-form-title{
    margin-bottom: 26px;
    color: #fff; 
    text-align: center;
    font-weight: 500;
}
.restore-pass{
    margin-top: 26px;
    display: block;
    color: #fff;
    font-size: 16px;
}
.auth-form{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    z-index: 2;
}
.auth-form-mt-13{
    margin-top: 13px;
}
.auth-card-form{
    width: 100%;
    height: 100%;
}
.auth-main-row-wrap{
    height: 100%;
    margin: 0;
}
.auth-main-row-wrap>div{
    padding: 50px 80px 10px 80px;
}
.auth-main-row-wrap>div.amrw-logo-part{
    background: rgba(168, 18, 28, .3);
}
.auth-card-form .v-card{
    background-color: transparent;
}
.auth-logo-row::v-deep img{
    display: block;
}
.auth-logo-row::v-deep img.al-logo{
    height: 56px;
}
.auth-logo-row::v-deep img.al-five{
    height: 60px;
}
.auth-logo-row::v-deep .auth-logo-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px 0 43px;
    position: relative;
}
.auth-logo-row::v-deep .auth-logo-title:after{
    content: '';
    position: absolute;
    width: 3px;
    top: 0;
    bottom: 0;
    background: #fff;
    left: 20px;
}
.auth-logo-row::v-deep .auth-logo-title>div{
    width: 100%;
    line-height: 1;
    text-transform: uppercase;
}
.auth-logo-row::v-deep .auth-logo-title .alt-main{
    color: #fff;
    font-weight: 500;
    font-size: 28px;
    z-index: 2;
    font-family: BAHNSCHRIFT !important;
}
.auth-logo-row::v-deep .auth-logo-title .alt-sub{
    color: #fff;
    font-size: 28px;
    letter-spacing: 1px;
    z-index: 2;
    font-family: BAHNSCHRIFT !important;
}
.auth-logo-row::v-deep .auth-logo-title .alt-sub span{
    font-size: 22px;
    font-style: italic;
    color: #054594;
}
.auth-logo-row::v-deep .logo-title-column .auth-logo-title{
    margin-top: 15px;
}
.auth-logo-row::v-deep .logo-title-column .auth-logo-title .alt-main, .auth-logo-row::v-deep .logo-title-column .auth-logo-title .alt-sub{
    text-align: center;
}
.auth-logo-row::v-deep .logo-title-column .auth-logo-title .alt-main{
    color: #000;
    font-size: 20px;
}
.auth-logo-row::v-deep .logo-title-column .auth-logo-title .alt-sub{
    font-size: 12px;
}
.auth-form .v-card__actions{
    justify-content: end;
}
.auth-action-box{
    display: flex;
    justify-content: end;
}
.auth-lang-selector .v-btn--has-bg{
    background-color: rgba(255, 255, 255, .2); 
    color: #fff; 
}
.auth-form-field-wrap{
    width: 575px;
    margin: 0 auto;
}
.login-form-selector-panel{
    margin-bottom: 26px;
}
.login-form-selector-panel.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active){
    color: #fff!important;
}
.tab-content-panel{
    overflow: visible!important;
}
.auth-loader{
    position: absolute!important;
    top: calc(50% - 25px);
    right: calc(50% - 25px);
}
.auth-form .v-card__text{
    padding: 0;
    height: 100%;
}
.send-auth-btn{
    color: #fff;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg{
    background-color: rgba(255, 255, 255, .3) !important;
}
.auth-copyright{
    margin-top: 26px;
    color: #fff;
    font-size: 16px;
    line-height: 1;
}
.bottom-panel-info{
    margin: 0 -70px;
}
.bpi-pattern{
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    -moz-border-radius: 4px; /* Firefox */
    -webkit-border-radius: 4px; /* Safari, Google Chrome */
    -khtml-border-radius: 4px; /* KHTML */
    -o-border-radius: 4px; /* Opera */
    -ms-border-radius: 4px; /* IE8 */
    -icab-border-radius: 4px; /* Icab */
    border-radius: 4px; /* CSS3 */
    font-size: 16px;
    padding: 5px 10px;
    display: inline-block;
}
.bpi-pattern a{
    color: #fff;
}
#bgVideo{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
}
#bgFolder{
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 0;
}
#bgVideoBlurLayer{
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 1;  
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}
.integro-red-color{
    background-color: rgba(168, 18, 28, 0.6) !important;
    border-color: rgba(168, 18, 28, 0.6) !important;
}

@media screen and (max-width: 1600px) {
    .auth-main-row-wrap>div{
        padding: 25px 40px 10px 40px;
    }
    .auth-form-field-wrap{
        width: 55%;
    }
    .auth-logo-row::v-deep .auth-logo-title .alt-main, 
    .auth-logo-row::v-deep .auth-logo-title .alt-sub{
        font-size: 20px;
    }
    .auth-logo-row::v-deep img.al-logo{
        height: 42px;
    }
    .auth-logo-row::v-deep img.al-five{
        height: 46px;
    }
    .bottom-panel-info{
        margin: 0 -30px;
    }
}
@media screen and (max-width: 780px) {
    .auth-form-field-wrap{
        width: 80%;
    } 
}

</style>